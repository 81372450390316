import { getApplicantCheckSummary, handleCheckSummaryData } from "@/api/checkSummary";
import { BusinessInfo, CheckSummary } from "@frankieone/shared";
import { getDocumentScans } from "@/api/document";
import { getApplicantDetails } from "@/api/applicant";
import CheckSummaryModel from "@/model/ApplicantCheck.js";
import ApplicantModel from "@/model/Applicant.js";
import { mkMutationName } from "@/store/helper";
export const setApplicantDetails = mkMutationName("set", "applicantDetails", "applicantDetails");
export const setBusinessInfo = mkMutationName("set", "businessInfo", "businessInfo");
export const setApplicantCheckSummary = mkMutationName("set", "applicantDetails", "checksummary");
export const setDocuments = mkMutationName("set", "applicantDetails", "documents");
export const setIndividualKycReports = mkMutationName("set", "applicantDetails", "individualKycReports");
export const setFilesForDocument = mkMutationName("set", "applicantDetails", "files");
export const setKycmethod = mkMutationName("set", "applicantDetails", "kycMethod");
export const clearCacheApplicantDetails = mkMutationName("clear", "applicantDetails");
export const setProfile = mkMutationName("set", "profile", "profile");
export const setLoading = mkMutationName("set", "loading", "isLoading");
export default {
  state: {
    applicantDetails: new ApplicantModel(),
    checkSummary: CheckSummary.default(),
    businessInfo: null,
    documents: [],
    kycMethod: null,
    profile: null,
    isLoading: false,
    individualKycReports: []
  },
  mutations: {
    [setApplicantDetails](state, data) {
      state.applicantDetails = data;
    },
    [setBusinessInfo](state, data) {
      state.businessInfo = data;
    },
    [setProfile](state, data) {
      state.profile = data;
    },
    [setApplicantCheckSummary](state, data) {
      state.checkSummary = handleCheckSummaryData({ data });
    },
    [setDocuments](state, data) {
      state.documents = data;
    },
    [setIndividualKycReports](state, data) {
      state.individualKycReports = data;
    },
    [setFilesForDocument](state, { scans, documentId }) {
      state.documents.forEach(document => {
        if (document.documentId === documentId) document.scans = scans;
      });
    },
    [setKycmethod](state, value) {
      state.kycMethod = value;
    },
    [setLoading](state, value) {
      state.isLoading = value;
    },
    [clearCacheApplicantDetails](state) {
      state.applicantDetails = new ApplicantModel();
      state.checkSummary = handleCheckSummaryData({ data: CheckSummary.default() });
      state.businessInfo = null;
      state.documents = [];
    }
  },
  actions: {
    setApplicantAssignee({ state, rootState }, email) {
      state.applicantDetails.assignee = email;
      const applicantId = state.applicantDetails.entityId;
      const applicant = (() => {
        const applicantList = rootState.applicants.applicantList;
        const idFinder = app => app.entityId === applicantId;
        const find = where => applicantList[where] && applicantList[where].find(idFinder);
        return find("onboarding") || find("monitoring");
      })();
      if (applicant) applicant.assignee = email;
    },
    async getApplicantDetails({ state, commit, dispatch }, entityId) {
      entityId = entityId || state.applicantDetails.entityId;
      commit(setLoading, true);
      const {
        applicantDetails,
        businessInfo,
        checkSummary,
        kycMethod,
        profile,
        documents,
        individualKycReports
      } = await getApplicantDetails(entityId);
      commit(setApplicantDetails, applicantDetails);
      commit(setBusinessInfo, businessInfo);
      commit(setApplicantCheckSummary, checkSummary);
      commit(setKycmethod, kycMethod);
      commit(setProfile, profile);
      //Set the documents data without files
      commit(setDocuments, documents);
      commit(setIndividualKycReports, individualKycReports);
      dispatch("getDocumentFiles", documents);
      commit(setLoading, false);
    },
    async getDocumentFiles({ commit }, documents) {
      return Promise.all(
        documents.map(async document => {
          try {
            const response = await getDocumentScans(document.documentId);
            document.scans = response;
            commit(setFilesForDocument, { scans: response, documentId: document.documentId });
            return response;
          } catch (err) {
            commit(setFilesForDocument, { scans: [], documentId: document.documentId });
            throw err;
          }
        })
      );
    }
  },
  getters: {
    isApplicantDetailsLoading: state => state.isLoading,
    applicantDetailsResolve: state => {
      if (!state.applicantDetails || state.isLoading) return new ApplicantModel();
      else return state.applicantDetails;
    },
    checkSummaryResolve: state => {
      if (!state.checkSummary || state.isLoading) return handleCheckSummaryData({ data: CheckSummary.default() });
      else return state.checkSummary;
    },
    documentsResolve: state => {
      if (!state.documents || state.isLoading) return [];
      else return state.documents;
    },
    currentApplicantId: state => {
      const det = state.applicantDetails;
      if (!det && !det.entityId) return null;
      return det.entityId;
    }
  }
};
